import { useEffect } from "react";
import VanillaTilt from "vanilla-tilt";
import {
  appleButtonImage,
  epicButtonImage,
  googleButtonImage,
  samsungButtonImage,
} from "~/utils/downloadButtonImage";
import DownloadButton from "./DownloadButton";

export default function DownloadButtons({ locale }): JSX.Element {
  // Tilt needs a proper react wrapper
  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll(".download-button"), {
      max: 5,
      speed: 300,
      scale: 1.15,
      glare: false,
    });
  }, []);

  return (
    <>
      <div
        className="columns is-multiline is-mobile is-centered"
        style={{ maxWidth: "1000px", margin: "0 auto" }}
      >
        <div className="download-button column is-three-fifths-mobile is-one-third-desktop level has-text-centered">
          <DownloadButton
            name="Epic Games Store Button"
            customClass="epic"
            targetUrl="https://installer.ega.ol.epicgames.com/download?id=2c0b01933a7ec664303f86432a22baf9"
            imageUrl={epicButtonImage(locale)}
          />
        </div>
        <div className="download-button column is-three-fifths-mobile is-one-third-desktop level has-text-centered">
          <DownloadButton
            name="Apple Store Button"
            customClass="apple"
            targetUrl="https://apps.apple.com/app/rocket-league-sideswipe/id1549027048"
            imageUrl={appleButtonImage(locale)}
          />
        </div>
        <div className="download-button column is-three-fifths-mobile is-one-third-desktop level has-text-centered">
          <DownloadButton
            name="Google Play Store Button"
            customClass="google"
            targetUrl="https://play.google.com/store/apps/details?id=com.Psyonix.RL2D"
            imageUrl={googleButtonImage(locale)}
          />
        </div>
      </div>
    </>
  );
}
